import React from "react";
import "./Projects.css";

export default function Projects(props) {
  return (
    <div className="Projects-Wrapper">
      <h4>My Portfolio</h4>
      <h1>Projects</h1>
    </div>
  );
}
